$(function () {
    $('.formcontainerblock').on('keypress', '.FormCaptcha .FormCaptcha__Input', function (event) {
        if (event.which === 13) {
            event.preventDefault();
            $(this).parent().parent().find('.Form__Submit .FormSubmitButton').click();
        }
    });

    //Added code to show SubmitSuccessMessage on page
    if (typeof $$epiforms !== 'undefined') {
        $$epiforms(document).ready(function myfunction() {
            $$epiforms(".EPiServerForms").on("formsSubmitted", function (event, param1, param2) {
                $('.Form__SubmitSuccessMessage').show();
            });
        });
    }
});