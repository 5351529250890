var form = $("#EuroCodeForm");
var $elements = form.find('input.validate, textarea.validate, select.validate');

function ValidateEuroCodeFormWhole(event) {
    event = event || window.event;
    var valid = validateJqueryInputElements($elements);

    if (!valid) {
        event.preventDefault();
        return;
    }
};