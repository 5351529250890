function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

// MY SIS MENU
$("#openmysismenu").click(function (event) {
    if (isTouchDevice()) {
        event.preventDefault();

        if ($("#my-sis-pane").css("display") == "none") {
            $("#my-sis-pane").css("display", "block");
        } else {
            $("#my-sis-pane").css("display", "none");
        }
    }
});

$("#mysismenu").mouseenter(function (event) {
    if (!isTouchDevice()) {
        $("#my-sis-pane").css("display", "block");
    }
});

$("#mysismenu").mouseleave(function (event) {
    $("#my-sis-pane").css("display", "none");
});


// ABO PICKER
$(".abo-picker").mouseenter(function (event) {
    if (!isTouchDevice() && GetNumberOfSubscriptions() > 1) {
        $(".abo-picker-body").css("display", "block");
    }
});

$(".abo-picker").mouseleave(function (event) {
    $(".abo-picker-body").css("display", "none");
});

function AboPickerMenuClick(selectedSubscriptionId, language) {
    if (isTouchDevice() && GetNumberOfSubscriptions() > 1) {
        if ($(".abo-picker-body").css("display") == "none") {
            $(".abo-picker-body").css("display", "block");
        } else {
            $(".abo-picker-body").css("display", "none");
        }
    } else {
        AboPickerLinkClick(selectedSubscriptionId, language);
    }
}

function AboPickerLinkClick(selectedSubscriptionId, language) {
    var currentSubId = Cookies.get('subscriptionId');
    if (currentSubId.toString() === selectedSubscriptionId.toString()) {
        // no change, but still redirect to subscription and the selected start page

        ChangeSubscriptionSelection(selectedSubscriptionId, language);
        return false;
    }

    // check for product(s) in CheckoutCartSubscription
    $.getJSON("/api/cart/subscriptioncartisempty", function (result) {
        if (result.data !== null && result.data === false) {
            // ask user if ok to empty cart
            if (!confirm($("#emptysubscriptioncartquestion").val())) {
                // not ok - keep current selected subscription & abort
                return false;
            }
            // ok - empty cart and change selected subscription
            $.getJSON("/api/cart/emptysubscriptioncart", function (result) {
                ChangeSubscriptionSelection(selectedSubscriptionId, language);
                return false;
            });
        } else {
            // cart empty to start with - go ahead with change
            ChangeSubscriptionSelection(selectedSubscriptionId, language);
            return false;
        }
    });
};

function ChangeSubscriptionSelection(selectedSubscriptionId, language) {
    Cookies.set('subscriptionId', selectedSubscriptionId, { path: '/', secure: false, domain: '', expires: 30 })
    if (language != '') {
        if (language === 'en') {
            window.location.href = "/en/subscription?id=" + selectedSubscriptionId;
        } else {
            window.location.href = "/abonnemang?id=" + selectedSubscriptionId;
        }
    } else {
        if (window.location.href.indexOf("/en/") != -1) {
            window.location.href = "/en/subscription?id=" + selectedSubscriptionId;
        } else {
            window.location.href = "/abonnemang?id=" + selectedSubscriptionId;
        }
    }
};

/* Hide subscription picker caret when there's only one subscription */
$(function () {
    if (GetNumberOfSubscriptions() <= 1) {
        $(".abo-picker").find(".caret").css("display", "none");
    }
});

function GetNumberOfSubscriptions() {
    return $(".abo-picker-body").children().length - 1;
}