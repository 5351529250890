function updateRequiredFieldLabel(field, valueIfRequired, actualValue) {
    
    $("label[for='" + field + "']").removeClass("required");

    if (valueIfRequired === actualValue)
        $("label[for='" + field + "']").addClass("required");

    hideShowOrgNo(actualValue, valueIfRequired);
}

function hideShowOrgNo(actualValue, valueIfRequired) {
    const euCountries = ["SE",
        "AT",
        "BE",
        "BG",
        "CY",
        "CZ",
        "DK",
        "EE",
        "ES",
        "FI",
        "FR",
        "HR", 
        "EL",
        "HU",
        "IE",
        "IT",
        "LT",
        "LU",
        "LV",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SI",
        "SK",
        "DE"];
    if (euCountries.includes(actualValue) && actualValue != valueIfRequired)
        $('.orgNoFields').css("display", "none");

    if (!euCountries.includes(actualValue) || actualValue == valueIfRequired)
        $('.orgNoFields').css("display", "block");
}