$(window).scroll($.throttle(500, function () {
    if (document.getElementById("newsfeedinfinitescroll") === null)
        return;

    if ($(window).scrollTop() >= $(document).height() - $(window).height() - 500) {
        var viewModel = ko.contextFor(document.getElementById("newsfeedinfinitescroll")).$data;
        if (viewModel.results().length > 0)
            viewModel.refresh();
    }
}));

function NewsFeed() {

    var that = this;

    that.showLoaderImage = ko.observable(true);
    that.results = ko.observableArray();

    that.refresh = function () {

        var offset = that.results().length;
        var aboContext = $("#newsfeedinfinitescroll").data("abocontext") == null ? false : true;

        $.getJSON("/api/newsfeed/getmore", { pageId: $("#newsfeedinfinitescroll").data("pageid"), offset: offset, pageSize: $("#newsfeedinfinitescroll").data("itemsperpage"), lang: window.displayLanguage, aboContext: aboContext })
            .done(function (result) {

                if (result.data !== null && result.data !== undefined) {
                    $.each(result.data.results, function () {
                        that.results.push(this);
                    });
                };

                that.showLoaderImage(false);
            });
    };

    that.refresh();
};

if (document.getElementById("newsfeedinfinitescroll") !== null)
    ko.applyBindings(new NewsFeed(), document.getElementById("newsfeedinfinitescroll"));