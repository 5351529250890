$(function (event) {
    AlternativeChange(event);
});

$(document).on('click','#userinformation-form *',function (event) {
    event.stopPropagation();
    if(event.target.id === 'my-settings-submit'){
        $('#userinformation-form').submit();
        AlternativeChange(event);
        var submitButton = $("#my-settings-submit");
        submitButton.toggleClass("btn-greyed-out");
        submitButton.addAttr('disabled');
    }
    if(event.target.id === 'AlternativeInvoiceAddress'){
        AlternativeChange(event);
    }
});

$("#userinformation-form").on('keyup change paste', 'input, select, textarea', function() {
    var submitButton = $("#my-settings-submit");
    submitButton.toggleClass("btn-greyed-out");
    submitButton.removeAttr('disabled');
});

function AlternativeChange(event) {
    $('#AlternativeInvoiceAddress').change(function (event) {
        var selectedValue = $(this).id;
        toggleVisibilityElements(selectedValue);
    });
}

function toggleVisibilityElements(selectedElement) {
    $("[visibility-constraint]").hide();
    $("[visibility-constraint]").each(function (e) {
        if ($($(this).attr('visibility-constraint')).is(':checked')) {
            $(this).show();
        } else {
            $(this).find('input:checkbox').prop('checked', false);
        }
    });
}
