//Update html binding to support virtual elements
var kooverridden = ko.bindingHandlers.html.update;
ko.bindingHandlers.html.update = function (element, valueAccessor) {
    if (element.nodeType === 8) {
        var html = ko.unwrap(valueAccessor());
        if (html != null) {
            var parsedNodes = ko.utils.parseHtmlFragment('' + html);
            ko.virtualElements.setDomNodeChildren(element, parsedNodes);
        } else {
            ko.virtualElements.emptyNode(element);
        }
    } else {
        kooverridden(element, valueAccessor);
    }
};
ko.virtualElements.allowedBindings.html = true;