function Countquest() {

    this.onViewProduct = function (name, id, price, category) {
        dataLayer.push({
            'ecommerce': {
                'detail': {
                    'actionField': { 'list': 'Startsida', 'action':'detail' },
                    'products': [{
                        'name': name,
                        'id': id,
                        'price': removeWhiteSpaces(price),
                        'category': category
                    }]
                }
            }
        });
    };

    this.addToCart = function (products, origin) {

        var addData = {
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'SEK',
                'add': {
                    'products': []
                }
            }
        };

        $.each(products, function (index, product) {
            addData.ecommerce.add.products.push(
                {
                    'name': product.name,
                    'id': product.code,
                    'price': removeWhiteSpaces(product.price),
                    'category': product.category,
                    'variant': product.variant,
                    'quantity': product.quantity,
                    'dimension7': origin
                }
            );
        });

        dataLayer.push(addData);
    };

    this.removeFromCart = function (name, code, price, category, variant, quantity) {
        dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
                'currencyCode': 'SEK',
                'remove': {
                    'products': [{
                        'name': name,
                        'id': code,
                        'price': removeWhiteSpaces(price),
                        'category': category,
                        'variant': variant,
                        'quantity': quantity
                    }]
                }
            }
        });
    };

    this.openStandard = function (name, code) {
        dataLayer.push({
            'event': 'openStandard',
            'name': name,
            'id': code
        });
    };

    this.previewStandard = function (name, code) {
        dataLayer.push({
            'event': 'previewStandard',
            'name': name,
            'id': code
        });
    };

    this.promoClick = function (id, name, creative, source) {

        var position = -1;
        var $teaser = $(source).closest(".teaser")[0];
        var $teasers = $(".block .teaser");
        for (var i = 0; i<$teasers.length;i++ )
        {
            if ($teasers[i] == $teaser) {
                position = i+1;
            }
        }

        dataLayer.push({
            'event': 'promoClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [{
                        'id': id,
                        'name': name,
                        'creative': creative,
                        'position': position
                    }]
                }
            },
        });
    };

    this.promoView = function (id, name, creative, position) {
        dataLayer.push({
            event: 'promoView',
            ecommerce: {
                promoView: {
                    promotions: [{
                        'id': id,
                        'name': name,
                        'creative': creative,
                        'position': position
                    }]
                }
            }
        });
    };

    this.promoViews = function (impressions) {
        dataLayer.push({
            event: 'promoView',
            ecommerce: {
                promoView: {
                    promotions: impressions
                }
            }
        });
    };

    this.productImpression = function (id, name, price, category, list, position) {
        dataLayer.push({
            event: 'impressions',
            ecommerce: {
                impressions:
                     [{
                        'id': id,
                        'name': name,
                        'price': price,
                        'category': category,
                        'list': list,
                        'position': position
                    }]
            }
        });
    };

    this.productImpressions = function (impressions) {
        dataLayer.push({
            event: 'impressions',
            ecommerce: {
                impressions:
                    impressions
            }
        });
    };

    this.checkOut = function (step, products, checkoutUrl) {

        var checkout = {
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': { 'step': step },
                    'products': []
                }
            }
        };

        $.each(products, function (index, product) {
            checkout.ecommerce.checkout.products.push(
                {
                    'name': product.name,
                    'id': product.code,
                    'price': removeWhiteSpaces(product.price),
                    'category': product.category,
                    'variant': product.variant,
                    'quantity': product.quantity
                }
            );
        });

        dataLayer.push(checkout);

        return true;
    };

    this.purchase = function (transaction, products) {

        var purchase = {
            'event': 'purchase',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': transaction.id,
                        'revenue': removeWhiteSpaces(transaction.revenue),
                        'tax': removeWhiteSpaces(transaction.tax),
                        'shipping': transaction.shipping,
                        'coupon': transaction.coupon
                    },
                    'products': [

                    ]
                }
            }
        };

        $.each(products, function (index, product) {
            purchase.ecommerce.purchase.products.push(
                {
                    'name': product.name,
                    'id': product.code,
                    'price': removeWhiteSpaces(product.price),
                    'category': product.category,
                    'variant': product.variant,
                    'quantity': product.quantity
                }
            );
        });

        dataLayer.push(purchase);

        return true;
    };

    this.pushEvent = function (category, action, label) {
        dataLayer.push({
            'event': 'trackEvent',
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label
        });

        return true;
    };

    this.logLogin = function () {
        var loginSuccess = getCookie('loginSuccess');
        if (loginSuccess !== '') {
            deleteCookie('loginSuccess');
            dataLayer.push({
                'event': 'login',
                'loginResult': 'success'
            });
        }
    };

    function removeWhiteSpaces(item) {
        if (item) {
            return item.toString().replace(/\s/g, "");
        }
        return item;
    }
}