var constraints = {
    email: {
        presence: {
            message: "^Du måste ange en E-postadress"
        },
        email: {
            message: "^E-postadressen har fel format"
        }
    },
    firstname: {
        presence: {
            message: "^Du måste fylla i ett förnamn"
        }
    },
    lastname: {
        presence: {
            message: "^Du måste fylla i ett efternamn"
        }
    },
    organization: {
        presence: {
            message: "^Du måste fylla i en organisation"
        }
    },
    phone: {
        presence: {
            message: "^Du måste ange ett telefonnummer"
        },
        format: {
            pattern: "^[0-9-+ ]*$",
            message: "^Telefonnummret har fel format"
        }
    },
    swedish: {
        presence: {
            message: "^Du måste bekräfta"
        },
        inclusion: {
            within: [true],
            message: "^Du måste kryssa i checkboxen"
        }
    },
    acceptedterms: {
        presence: {
            message: "^Du måste bekräfta"
        },
        inclusion: {
            within: [true],
            message: "^Du måste kryssa i checkboxen"
        }
    }
};

function showErrorsForInputWithJquery($element, errors) {
    var valid = true;
    var formGroup = $element.parent('div.form-group');

    formGroup.removeClass('has-error');
    formGroup.find('span.help-block.has-error').remove();

    $.each(errors, function (error, value) {
        var lowerError = error.toLowerCase();
        var lowerName = $element.attr('name').toLowerCase();

        if (lowerError === lowerName) {
            formGroup.addClass("has-error");
            formGroup.append('<span class="help-block has-error">' + value + '</span>');
            valid = false;
        }
    });

    return valid;
};

function validateJqueryInputElements($elements) {
    var data = Object();
    var valid = true;

    $elements.each(function () {
        var $element = $(this);

        var name = $element.attr('name').toLowerCase();
        var value = $element.val();
        if ($element.is(':checkbox')) {
            if ($element.prop('checked') === true) {
                value = true;
            }
            else {
                value = false;
            }
        }
        data[name] = value;
    });

    var jsonData = JSON.stringify(data);
    var errors = validate(JSON.parse(jsonData), constraints) || {};

    $elements.each(function () {
        var $element = $(this);
        var elementIsValid = showErrorsForInputWithJquery($element, errors);

        if (!elementIsValid)
            valid = false;
    });

    return valid;
}

$(function () {
    var inputsToValidate = $('input.validate, textarea.validate, select.validate');

    inputsToValidate.each(function () {
        var $this = $(this);

        $this.on('focusout', function (e) {
            var $this = $(this);
            var name = $this.attr('name').toLowerCase();
            var value = $this.val();
            if ($this.is(':checkbox')) {
                if ($this.prop('checked') === true) {
                    value = true;
                }
                else {
                    value = false;
                }
            }
            var data = Object();
            data[name] = value;
            var jsonData = JSON.stringify(data);

            var errors = validate(JSON.parse(jsonData), constraints) || {};
            showErrorsForInputWithJquery($this, errors);
        });
    });
});