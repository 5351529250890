if (document.getElementById("large-order-container") !== null) {
    $(window).scroll($.throttle(800, function () {

        if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
            var viewModel = ko.contextFor(document.getElementById("large-order-container")).$data;
            if (viewModel.orders().length > 0)
                viewModel.GetOrders();
        }
    }));
}
function OrderHistory(numberofposts, expanded, enableLoadMore) {
    var that = this;

    that.showLoaderImage = ko.observable(true);
    that.orders = ko.observableArray();
    that.totalcount = ko.observable(0);

    that.GetOrders = function (numberofposts, contactId) {
        if (that.orders().length < 1 || (enableLoadMore === true && that.orders().length > 0 && that.orders().length < that.totalcount)) {
            that.showLoaderImage(true);

            $.ajax({
                type: "GET",
                url: "/api/orderhistory/GetOrders?take=" + numberofposts + "&offset=" + that.orders().length + "&contactId=" + contactId,
                cache: false,
                timeout: 30000,
                retries: 3,
                retryInterval: 2000,
                success: function (result) {
                    if (isJson(result.data)) {
                        if (result.data !== null && result.data !== undefined) {
                            that.totalcount = result.data.totalCount;
                            $.each(result.data.orders, function () {
                                that.orders.push(new Order(this));
                            });
                            that.showLoaderImage(false);
                        }
                    } else {
                        that.showLoaderImage(false);
                        this.retries--;
                        if (this.retries > 0) {
                            $.ajax(this);
                            return;
                        }
                        return;
                    }
                    $(".loading-orderhistory").hide();
                },
                error: function (xhr, textStatus, errorThrown) {
                    that.showLoaderImage(false);
                    this.retries--;
                    if (this.retries > 0) {
                        $.ajax(this);
                        return;
                    }
                    return;
                }
            });
        }
        return true;
    };
    if (expanded === true) {
        that.GetOrders();
    }
}

function Order(item) {
    var that = this;

    that.showLoaderItemsImage = ko.observable(true);
    that.orderlineitems = ko.observableArray();
    that.number = ko.observable(item.number);
    that.invoicenumber = ko.observable(item.invoiceNumber);
    that.date = ko.observable(item.created);
    that.total = ko.observable(item.total);
    that.tax = ko.observable(item.tax);
    that.billingcurrency = ko.observable(item.billingCurrency);
    that.discount = ko.observable(item.discount);
    that.status = ko.observable(item.status);

    if (item.shippingAddress == null) {
        item.shippingAddress = {
            FirstName: "",
            LastName: "",
            Line1: "",
            Line2: "",
            City: "",
            PostalCode: "",
            CountryName: ""
        }
    }

    that.firstname = ko.observable(item.shippingAddress.FirstName);
    that.lastname = ko.observable(item.shippingAddress.LastName);
    that.line1 = ko.observable(item.shippingAddress.Line1);
    that.line2 = ko.observable(item.shippingAddress.Line2);
    that.city = ko.observable(item.shippingAddress.City);
    that.zipcode = ko.observable(item.shippingAddress.PostalCode);
    that.country = ko.observable(item.shippingAddress.CountryName);

    that.GetLineItems = function () {
        if (that.orderlineitems().length < 1) {
            that.showLoaderItemsImage(true);
            $.getJSON("/api/orderhistory/GetOrderItems", { trackingNumber: that.number() })
                .done(function (result) {
                    if (result.data !== null && result.data !== undefined) {
                        $.each(result.data.items, function () {
                            that.orderlineitems.push(new OrderLineItem(this));
                        });
                    }
                    that.showLoaderItemsImage(false);
                });
        }
    }

    that.GetInvoice = function () {
        var invoiceUrl = '/api/orderhistory/GetInvoice?trackingNumber=' + that.number();
        window.open(invoiceUrl, '_blank');
    }
}

function OrderLineItem(item) {
    var that = this;

    that.name = ko.observable(item.name);
    that.category = ko.observable(item.category);
    that.subcategory = ko.observable(item.subCategory);
    that.description = ko.observable(item.description);
    that.quantity = ko.observable(item.quantity);
    that.price = ko.observable(item.price);
    that.pricetext = ko.observable(item.priceText);
    that.currency = ko.observable(item.currency);
    that.total = ko.observable(item.total);
    that.totaltext = ko.observable(item.totalText);
    that.discount = ko.observable(item.discount);
    that.discounttext = ko.observable(item.discountText);
    that.designation = ko.observable(item.designation);
    that.type = ko.observable(item.type);
}

if (document.getElementById("large-order-container") !== null)
    ko.applyBindings(new OrderHistory(10, true, true), document.getElementById("large-order-container"));

if (document.getElementById("order-container") !== null)
    ko.applyBindings(new OrderHistory(5, false, false), document.getElementById("order-container"));

/* MY STANDARDS */
$(window).scroll($.throttle(800, function () {
    if (document.getElementById("my-standards-page") === null)
        return;

    if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
        var viewModel = ko.contextFor(document.getElementById("my-standards-page")).$data;
        if (viewModel.standards().length > 0)
            viewModel.GetStandards();
    }
}));

function MyStandardsList(numberofposts, expanded, enabledLoadMore) {
    var that = this;

    that.showLoaderImage = ko.observable(true);
    that.standards = ko.observableArray();
    that.totalcount = ko.observable(0);

    that.GetStandards = function () {
        if (that.standards().length < 1 || (enabledLoadMore === true && that.standards().length > 0 && that.standards().length < that.totalcount)) {
            that.showLoaderImage(true);

            $.ajax({
                type: "GET",
                url: "/api/mystandards/GetTheStandards?take=" + numberofposts + "&offset=" + that.standards().length,
                cache: false,
                timeout: 10000,
                retries: 3,
                retryInterval: 2000,
                success: function (result) {
                    if (isJson(result.data)) {
                        if (result.data !== null && result.data !== undefined) {
                            that.totalcount = result.data.totalCount;
                            $.each(result.data.standards, function () {
                                that.standards.push(new StandardReference(this));
                            });
                            that.showLoaderImage(false);
                        }
                    } else {
                        that.showLoaderImage(false);
                        this.retries--;
                        if (this.retries > 0) {
                            $.ajax(this);
                            return;
                        }
                        return;
                    }
                },
                error: function (xhr, textStatus, errorThrown) {
                    that.showLoaderImage(false);
                    this.retries--;
                    if (this.retries > 0) {
                        $.ajax(this);
                        return;
                    }
                    return;
                }
            });
        }
    };
    if (expanded === true) {
        that.GetStandards();
    }
}

function daysAgo(date) {
    return Math.abs(Math.ceil((new Date(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24)))
}

function StandardReference(item) {
    var that = this;

    that.title = ko.observable(item.title);
    that.standardid = ko.observable(item.id);
    that.url = ko.observable(item.url);
    that.designation = ko.observable(item.designation);
    that.icon = ko.observable(item.icon);
    that.type = ko.observable(item.type);
    that.target = ko.observable(item.target);
    that.paymentDate = ko.observable(item.paymentDate);
    that.linkToAddendum = ko.observable(item.linkToAddendum);
}

if (document.getElementById("my-standards") !== null)
    ko.applyBindings(new MyStandardsList(5, false, false), document.getElementById("my-standards"));

if (document.getElementById("my-standards-page") !== null)
    ko.applyBindings(new MyStandardsList(10, true, true), document.getElementById("my-standards-page"));

/* MY SERVICES */
function MyServices(numberofposts, isExpanded) {
    var that = this;

    that.showLoaderImage = ko.observable(true);
    that.services = ko.observableArray();

    that.GetServices = function () {
        if (that.services().length < 1) {
            that.showLoaderImage(true);
            $.getJSON("/api/subscription/GetServicesAndSubscriptions", { take: numberofposts })
                .done(function (result) {
                    that.showLoaderImage(false);
                    if (result !== null && result !== undefined) {
                        $.each(result, function () {
                            that.services.push(new EnavService(this));
                        });
                    }
                });
        }
    };

    if (isExpanded === true) {
        that.GetServices();
    }
}

function EnavService(item) {
    var that = this;

    that.iconUrl = ko.observable(item.iconUrl);
    that.title = ko.observable(item.title);
    that.subId = ko.observable(item.subId);
    that.url = ko.observable(item.enavUrl);
    that.role = ko.observable(item.roles);
    that.typeOfAuthentication = ko.observable(item.typeOfAuthentication);
}

if (document.getElementById("my-services") !== null)
    ko.applyBindings(new MyServices(0, true), document.getElementById("my-services"));

if (document.getElementById("my-services-block") !== null)
    ko.applyBindings(new MyServices(5, false), document.getElementById("my-services-block"));


$('.TypeOfMembership').on('change', function (e) {
    $('.company-fields').toggle();

    if (e.currentTarget.value === "true") {
        $('#alternativeAdressDiv').css('display', 'block');
    }
    else {
        $('#alternativeAdressDiv').css('display', 'none');

    }
});
